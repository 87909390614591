import React, { useEffect, useMemo } from 'react';
import { Formik } from 'formik';

// MUI Imports
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

import FormControl from '@mui/material/FormControl';
import {
  Autocomplete,
  Chip,
  DialogActions,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import AddLocation from '../addLocation/AddLocation';
import { adminLocations, getAdminOrgLevels } from '../../../redux/userActions';
import { useDispatch } from 'react-redux';
import { modifyUsers } from '../../../redux/userActions';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export default function UpdateUser(props) {
  const {
    onClose,
    open,
    roles,
    analyticUpdated,
    setAnalyticUpdated,
    user,
    ...other
  } = props;
  const [duplicate, setDuplicate] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [locations, setLocations] = React.useState([]);
  const [orgLevels, setOrgLevels] = React.useState();
  const [userLevel, setUserLevel] = React.useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    fetchData();
    setOpenPopup(false);
  };

  useEffect(() => {
    const fetchOrgLevels = async () => {
      const res = await getAdminOrgLevels();
      setOrgLevels(res.data);
    };
    fetchOrgLevels();
  }, []);

  React.useEffect(() => {
    let ChildLevels = null;
    switch (user?.orgLevel) {
      case 'EPI':
        ChildLevels = 'EPI';
        break;
      case 'DISTRICT':
        ChildLevels = 'DISTRICT';
        break;
      case 'UPAZILA':
        ChildLevels = 'UPAZILA';
        break;
      case 'MUNICIPALITY':
        ChildLevels = 'MUNICIPALITY';
        break;
      case 'CITY':
        ChildLevels = 'CITY';
        break;
      case 'ZONE':
        ChildLevels = 'ZONE';
        break;
      case 'WARD':
        ChildLevels = 'WARD';
        break;
      case 'OUTREACH_CENTERS':
        ChildLevels = 'OUTREACH_CENTERS';
        break;
      default:
        ChildLevels = null;
        break;
    }
    fetchData(ChildLevels);
  }, [user?.orgLevel]);

  const fetchData = async (level) => {
    if (level) {
      const res = await adminLocations(level);
      setLocations(res?.data?.data);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.role) {
      errors.role = t('required');
    }
    if (!values.location) {
      errors.location = t('required');
    }

    return errors;
  };

  const initialValues = {
    role: user?.role?._id || '',
    location: user?.locationIds || '',
  };

  const handleFormSubmit = async (values) => {
    const updatedBody = {
      userId: user._id,
      status: user.accountStatus,
      roleId: values.role,
      locationIds: values.location,
    };

    const res = await modifyUsers(updatedBody);
    if (res.success) {
      toast.success(t('user_updated'));
      setAnalyticUpdated((prev) => !prev);
      onClose();
    } else {
      toast.error(t('something_went_wrong'));
      onClose();
    }
  };

  const districtOptions = useMemo(
    () =>
      locations?.map((district) => ({
        id: district?._id,
        name: district?.name,
      })),
    [locations]
  );

  return (
    <React.Fragment>
      <Dialog
        sx={{
          '& .MuiDialog-paper': { width: '100%' },
        }}
        className='ModalPopup__dialog'
        maxWidth='sm'
        open={open}
        {...other}
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle sx={{ padding: '0 !important' }}>
                <div className='ModalPopup__header'>
                  <div className='ModalPopup__header__title_fs'>
                    {t('update_user_title')}
                  </div>
                  <div
                    className='ModalPopup__close_icon'
                    onClick={() => {
                      handleReset();
                      onClose();
                    }}
                  >
                    <i className='fa-solid fa-xmark'></i>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent
                sx={{
                  padding: '0 !important',
                  borderRadius: '0.6rem !important',
                }}
              >
                <div className='ModalPopup__form_container'>
                  <div className='ModalPopup__wrapper'>
                    <div className='ModalPopup__body'>
                      <div className='ModalPopup__form_section'>
                        <div className='ModalPopup__label_header'>
                          <h1 className='Input__label_fs'>
                            {t('update_user_subtitle')}
                          </h1>
                        </div>

                        <div className='Input__row two_column'>
                          <div className='Input__column'>
                            <p className='Input__label_fs'>
                              {user?.firstName &&
                                user?.lastName &&
                                t('user_name')}
                            </p>
                            <div className='otp_email_verify'>
                              <h1
                                style={{
                                  fontSize: '12px',
                                  marginTop: '-0.5rem',
                                }}
                              >
                                {user?.firstName} {user?.lastName}
                              </h1>
                            </div>
                          </div>
                          <div className='Input__column'>
                            <p className='Input__label_fs'>
                              {user?.emailId && t('email_address')}{' '}
                              {user?.phoneNumber &&
                                !user?.emailId &&
                                t('phone_number')}
                            </p>
                            <div className='otp_email_verify'>
                              <h1
                                style={{
                                  fontSize: '12px',
                                  marginTop: '-0.5rem',
                                }}
                              >
                                {user?.emailId}{' '}
                                {user?.phoneNumber &&
                                  !user?.emailId &&
                                  user?.phoneNumber}
                              </h1>
                            </div>
                          </div>
                        </div>

                        <div className='Input__table'>
                          <div className='Input__column'>
                            <p className='Input__label_fs'>
                              {t('location')}
                              <b>*</b>
                            </p>

                            <FormControl fullWidth>
                              <Autocomplete
                                multiple
                                id='tags-filled'
                                size='small'
                                className='mui_custom_autocomplete'
                                options={districtOptions}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) =>
                                  option?.id === value?.id
                                }
                                value={districtOptions?.filter((option) =>
                                  values?.location?.includes(option?.id)
                                )}
                                onChange={(event, value) => {
                                  setFieldValue(
                                    'location',
                                    value.map((option) => option?.id)
                                  );
                                }}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant='outlined'
                                      label={option?.name}
                                      {...getTagProps({ index })}
                                      key={option?.id}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className='mui_custom_autocomplete'
                                    size='small'
                                    variant='outlined'
                                    placeholder={
                                      values?.location?.length > 0
                                        ? ''
                                        : 'Select locations'
                                    }
                                    sx={{ borderRadius: '8px' }}
                                  />
                                )}
                              />

                              {errors.location && touched.location && (
                                <span className='Input__error_fs'>
                                  {errors.location}
                                </span>
                              )}
                            </FormControl>
                          </div>
                          <div className='Input__column'>
                            <p className='Input__label_fs'>
                              {t('user_role')}
                              <b>*</b>
                            </p>

                            <FormControl fullWidth>
                              <Select
                                className='mui_custom_input_field'
                                size='small'
                                id='role'
                                name='role'
                                onChange={handleChange}
                                value={values.role}
                              >
                                {roles?.map((level, index) => (
                                  <MenuItem key={index} value={level?._id}>
                                    {level?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.role && touched.role && (
                                <span className='Input__error_fs'>
                                  {errors.role}
                                </span>
                              )}
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div className='ModalPopup__action_wrapper_start'>
                  <div className='ModalPopup_actions'>
                    <button
                      type='button'
                      className='mi_btn mi_btn_medium mi_btn_outline'
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenPopup();
                      }}
                    >
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {' '}
                        {t('add_location_button')}
                      </span>
                    </button>
                  </div>
                </div>
                <div className='ModalPopup__action_wrapper'>
                  <div className='ModalPopup_actions'>
                    <button
                      type='submit'
                      className='mi_btn mi_btn_medium mi_btn_primary'
                    >
                      <span> {t('save')}</span>
                    </button>
                  </div>
                </div>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>

      <AddLocation
        keepMounted
        open={openPopup}
        locations={locations}
        orgLevels={orgLevels}
        onClose={handleClosePopup}
      />
    </React.Fragment>
  );
}
